import React from "react";
import SocialShare from "../SocialShare/SocialShare";

const FundraisingInfo = () => (
  <section className="container mt-5" style={{marginBottom: "50px", marginTop: "100px"}}>
    <div className="row">
      {/* Video Column */}
      <div className="col-md-6">
        <div className="ratio ratio-16x9">
          <iframe
            src="https://player.vimeo.com/video/1038710613?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
            title="Video sobre o projeto"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      
      {/* Text Column */}
      <div className="col-md-6">
        <h4 className="text-uppercase" style={{ fontWeight: "bold", color:"#614098" }}>
          Realizando o Sonho de Surfar
        </h4>
        <p className="mt-3">
          Vamos apresentando os participantes e falando um pouco mais da realização<br/> desse sonho que é surfar na <strong>@surflandbrasil</strong>!
        </p>
        <p>
          Seguimos a campanha para levar 4 participantes do projeto <strong>Mergulho Inclusivo</strong> para fazer sua primeira Surf Trip.
        </p>
        <p>
          Como nosso projeto é social, estamos arrecadando fundos<br/> para realização desse sonho.
        </p>
        <p className="" style={{ fontWeight: "", fontSize: "18px" }}>
          Toda arrecadação financeira está sendo feita através da chave pix: <strong style={{ color: "#614098" }}>ondasarpoador@gmail.com</strong>
        </p>
        <p>
          Estamos utilizando uma conta somente para realização desse evento, pois todas plataformas pedem uma porcentagem em cima de toda arrecadação.
        </p>
        <p>
          Se você não puder contribuir com algum valor financeiro, pode ajudar compartilhando esse vídeo. Toda ajuda é muito bem-vinda!!!
        </p>
        <SocialShare/>
      </div>
    </div>
    
  </section>
);

export default FundraisingInfo;

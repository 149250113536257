import React from "react";

// Assets

import Whatsapp from "./img/whatsapp.png";
import Surfers1 from "./img/fotosSurfers.png";
import ProfileRogerio from "./img/profile-rogerio.png";
import ProfileTito from "./img/profile-tito.png";
import Perfil01 from "./img/profile-01.jpeg";
import Perfil02 from "./img/profile-02.jpeg";
import Perfil03 from "./img/profile-03.jpeg";
import Perfil04 from "./img/profile-04.jpeg";
import Ondas1 from "./video/ondas.mp4";
import Ondas2 from "./video/ondas-2.mp4";
import Projeto from "./video/projeto_ondas.mp4";
import instagram from "../../img/instagram.png";
import FundraisingInfo from "../FundraisingInfo/FundraisingInfo";

const videos = [
  {
    title: "Jorge Lucas: Ator e Dublador",
    src: Ondas1,
  },
  {
    title:
      "Marcelo Bispo: Fundador da Team Bispo (escola de surf) e ex-surfista profissional",
    src: Ondas2,
  },
];

const profiles = [
  {
    name: "Lucas Mele 11 anos",
    description:
      "Surfa há 4 meses e cada vez quer surfar ondas maiores. Mesmo com paralisia cerebral, está cada vez mais independente, querendo remar sozinho e ter cada vez menos ajuda da equipe para entrar nas ondas.",
    img: Perfil01,
  },
  {
    name: "Paola Mele 7 anos",
    description:
      "Surfa há 4 meses, tem excelente base, desce todas ondas sem cair e encanta todo mundo com seu estilo próprio.",
    img: Perfil02,
  },
  {
    name: "Tito Gomes 64 anos",
    description:
      "Surfa há 6 meses, já consegue se divertir em condições favoráveis. Segue perdendo peso e muito feliz com os hábitos adquiridos ao se tornar surfista.",
    img: Perfil03,
  },
  {
    name: "Paula Seixas 56 anos",
    description:
      "Paula já é nossa aluna há alguns anos. Faz um ano que ela se tornou deficiente visual e há 3 meses reaprendeu a surfar. Segue conquistando sua autonomia e cada vez mais feliz em seu processo.",
    img: Perfil04,
  },
];

const Content = () => (
  <>

  <FundraisingInfo />
    {/* Main Section */}
    <section id="main" className="container border-box container-services">
      <div className="row">
        <div className="col-md-6 border-box">
          <p className="text-uppercase text-center text-videos">
            PROJETO ONDAS ARPOADOR
          </p>
          <video className="ratio ratio-4x3" autoPlay controls>
            <source src={Projeto} type="video/mp4" />
          </video>
        </div>
        <div className="col-md-6 mt-5">
          <p className="mt-3">
            As estimativas iniciais indicam que, aproximadamente, 450 milhões de
            pessoas no mundo sofrem de transtornos mentais e problemas
            psicossociais, como os relacionados com o uso abusivo do álcool e
            das drogas.
            <br />
            O Brasil é o País com o maior número de ansiosos crônicos (9,3% da
            população de acordo com a OMS), e aproximadamente 30 milhões de
            brasileiros conhecem um familiar dependente químico.
            <br />
            A saúde mental depende da qualidade de vida, resgate das habilidades
            sociais, respeito próprio, relações interpessoais e do trabalho que
            formam a base estrutural para a construção de uma vida melhor.
          </p>
          <a
            href="https://api.whatsapp.com/send/?phone=5521991133070&text&app_absent=0"
            target="_blank"
            className="btn btn-primary mt-4 mb-4"
            rel="noreferrer"
          >
            <span style={{ fontWeight: "bold", color: "white" }}>
              Mais Informações
            </span>
          </a>
        </div>
      </div>
    </section>

    {/* Videos Section */}
    <section id="videos" className="container">
      <div className="row">
        {videos.map((video, index) => (
          <div className="col-md-6" key={index}>
            <p className="text-uppercase text-center text-videos">{video.title}</p>
            <video className="ratio ratio-4x3" src={video.src} controls></video>
          </div>
        ))}
      </div>
    </section>

    {/* Who We Are Section */}
    <div className="container container-services">
      <div className="row">
        <div className="col-md-6 box-center">
          <img src={Surfers1} className="img-center img-fluid" alt="Surfers" />
        </div>
        <div className="col-md-6 box-center">
          <h4 style={{ fontWeight: "700" }} className="text-left text-uppercase">
            Quem somos
          </h4>
          <h5>Tito Gomes</h5>
          <p className="text-left">
            Terapeuta com mais de 25 anos de atuação na cidade do Rio de Janeiro.
            Conselheiro pela London School of Counseling and Therapy. Conselheiro
            The Tavistock Clinic Terapia Sistêmica Familiar e Organizacional Priory
            Hospital. Conselheiro em Alcoolismo e Dependência Química NEPAD (UERJ).
          </p>
          <img
            src={ProfileTito}
            className="img-center img-fluid"
            alt="Tito Gomes"
          />

          <h5>Rogério Pessoa</h5>
          <p className="text-left">
            Conselheiro em dependência química desde 2016. Trabalhou na Clínica
            Evolução e no Espaço Village, com atividades de prevenção à recaída e
            reinserção social. Professor de surf, atuou por dois anos no projeto
            SURF CARE.
          </p>
          <img
            src={ProfileRogerio}
            className="img-center img-fluid"
            alt="Rogério Pessoa"
          />
        </div>
      </div>
    </div>

    {/* Testimonials Section */}
    <div className="container container-services">
      <div className="row">
        {profiles.map((profile, index) => (
          <div className="col-md-3" key={index}>
            <div>
              <img
                src={profile.img}
                className="card-img-top img-circle"
                alt={`Depoimento de ${profile.name}`}
              />
              <div className="card-body">
                <p className="card-text">
                  <strong>{profile.name}</strong>
                  <br />
                  {profile.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Contact Section */}
    <div className="container">
      <p className="text-left">
        <a
          href="https://www.instagram.com/ondasarpoador/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#333", fontWeight: "medium", fontSize: "15px" }}
        >
          <img src={instagram} alt="Instagram" title="Instagram" />
          <span style={{ color: "#333" }}> @ondasarpoador</span>
        </a>
      </p>
      <div className="row">
        <div className="col-md-6">
          <a
            href="https://api.whatsapp.com/send/?phone=5521991133070&text&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-2">
              <img src={Whatsapp} width="28" height="28" alt="WhatsApp" />
              <span style={{ fontWeight: "bolder" }}> 21 99113-3070 </span>
            </button>
          </a>
          <p>ondasarpoador@gmail.com</p>
        </div>
      </div>
    </div>
  </>
);

export default Content;

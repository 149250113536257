import React, { useMemo } from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaCommentDots } from "react-icons/fa";

const SocialShare = ({ url }) => {
  const shareText = useMemo(() => encodeURIComponent("Confira essa incrível campanha para realizar o sonho de surfar! 🌊"), []);
  const shareUrl = useMemo(() => encodeURIComponent(url || ""), [url]);

  const socialLinks = useMemo(() => ({
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    instagram: "https://www.instagram.com/",
    threads: "https://www.threads.net/",
    whatsapp: `https://wa.me/?text=${shareText}%20${shareUrl}`,
  }), [shareText, shareUrl]);

  return (
    <div className="d-flex justify-content-center gap-3 mt-4">
      {Object.entries(socialLinks).map(([platform, link]) => (
        <a
          key={platform}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={`text-${platform === "facebook" ? "primary" : platform === "instagram" ? "danger" : platform === "threads" ? "secondary" : "success"}`}
          aria-label={`Compartilhar no ${platform.charAt(0).toUpperCase() + platform.slice(1)}`}
        >
          {platform === "facebook" && <FaFacebook size={28} />}
          {platform === "instagram" && <FaInstagram size={28} />}
          {platform === "threads" && <FaCommentDots size={28} />}
          {platform === "whatsapp" && <FaWhatsapp size={28} />}
        </a>
      ))}
    </div>
  );
};

export default SocialShare;
